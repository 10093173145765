<template>
  <div>
    <a-modal width="800px" v-model="visible" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div slot="title">{{ form.id ? "编辑客户" : "新增客户" }}</div>
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
          <a-form-model-item prop="name" label="客户名称">
            <a-input v-model="form.name" />
          </a-form-model-item>
          <a-form-model-item prop="number" label="客户编号">
            <a-input v-model="form.number" />
          </a-form-model-item>
          <a-form-model-item prop="level" label="等级">
            <a-select v-model="form.level" style="width: 100%">
              <a-select-option v-for="item in levelOptions" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="contact" label="联系人">
            <a-input v-model="form.contact" />
          </a-form-model-item>
          <a-form-model-item prop="code" label="统一社会信用代码/注册号/身份证号">
            <a-input v-model="form.others.credit_code.code" />
          </a-form-model-item>
          <a-form-model-item prop="valid_period" label="有效期限">
            <a-range-picker v-model="form.others.credit_code.valid_period" />
          </a-form-model-item>
          <a-form-model-item prop="phone" label="联系电话">
            <a-input v-model="form.phone" />
          </a-form-model-item>
          <a-form-model-item prop="address" label="联系地址">
            <a-input v-model="form.address" />
          </a-form-model-item>
          <div style="border-bottom: 1px solid #ccc; margin-bottom: 20px">
            <a-form-model-item prop="ID_image" label="证件图片">
              <Upload :ID_image="form.others.credit_code.ID_image" :count="5" @handleChange="handleChange" />
            </a-form-model-item>
          </div>
          <a-form-model-item prop="code" label="组织机构代码">
            <a-input v-model="form.others.organization_code.code" />
          </a-form-model-item>
          <a-form-model-item prop="valid_period" label="有效期限">
            <a-range-picker v-model="form.others.organization_code.valid_period" />
          </a-form-model-item>
          <div style="border-bottom: 1px solid #ccc; margin-bottom: 20px">
            <a-form-model-item prop="ID_image" label="证件图片">
              <Upload :ID_image="form.others.organization_code.ID_image" :count="5" @handleChange="handleChange1" />
            </a-form-model-item>
          </div>
          <a-form-model-item prop="code" label="食品生产许可证编号">
            <a-input v-model="form.others.license_number.code" />
          </a-form-model-item>
          <a-form-model-item prop="valid_period" label="有效期限">
            <a-range-picker v-model="form.others.license_number.valid_period" />
          </a-form-model-item>
          <div style="border-bottom: 1px solid #ccc; margin-bottom: 20px">
            <a-form-model-item prop="ID_image" label="证件图片">
              <Upload :ID_image="form.others.license_number.ID_image" :count="5" @handleChange="handleChange2" />
            </a-form-model-item>
          </div>
          <a-form-model-item prop="email" label="邮箱">
            <a-input v-model="form.email" />
          </a-form-model-item>
          <a-form-model-item prop="bank_account" label="银行账户">
            <a-input v-model="form.others.bank_account" />
          </a-form-model-item>
          <a-form-model-item prop="bank_name" label="开户行">
            <a-input v-model="form.others.bank_name" />
          </a-form-model-item>
          <a-form-model-item prop="remark" label="备注">
            <a-input v-model="form.remark" allowClear />
          </a-form-model-item>
          <a-form-model-item prop="is_active" label="状态">
            <a-select v-model="form.is_active" style="width: 100%">
              <a-select-option :value="true">激活</a-select-option>
              <a-select-option :value="false">冻结</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="initial_arrears_amount" label="初期欠款金额">
            <a-input-number v-model="form.initial_arrears_amount" style="width: 100%" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
  import Upload from '../../../components/Upload/index'
  import { clientCreate, clientUpdate } from "@/api/basicData";

  export default {
    name: "FormModal",
    props: ["visible", "form", "suppliersClassificationOptions"],
    model: { prop: "visible", event: "cancel" },
    components: {
      Upload
    },
    data() {
      return {
        levelOptions: [
          { id: "0", name: "0" },
          { id: "1", name: "1" },
          { id: "2", name: "2" },
          { id: "3", name: "3" },
        ],
        uploadUrl: process.env.VUE_APP_NEW_URL + '/comapi/UploadApi',
        rules: {
          name: [
            { required: true, message: "请输入名称", trigger: "blur" },
            { max: 64, message: "超出最大长度 (64)", trigger: "blur" }
          ],
          number: [
            { required: true, message: "请输入编号", trigger: "blur" },
            { max: 32, message: "超出最大长度 (32)", trigger: "blur" },
          ],
          initial_arrears_amount: [
            { pattern: new RegExp(/^\d{0,14}(?:\.\d{0,2})?$/), message: "初期欠款金额格式不正确", trigger: "change" },
          ],
        },
        loading: false,
      };
    },
    methods: {
      handleChange(val) {
        this.$set(this.form.others.credit_code,'ID_image',val);
      },
      handleChange1(val) {
        this.$set(this.form.others.organization_code,'ID_image',val);
      },
      handleChange2(val) {
        this.$set(this.form.others.license_number,'ID_image',val);
      },
      confirm() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.loading = true;
            let form = this.form;
            let func = form.id ? clientUpdate : clientCreate;

            func(form)
                    .then(() => {
                      this.$message.success(form.id ? "修改成功" : "新增成功");
                      this.$emit(form.id ? "update" : "create");
                      this.cancel();
                    })
                    .finally(() => {
                      this.loading = false;
                    });
          }
        });
      },
      cancel() {
        this.$emit("cancel", false);
        if(!this.form.id) {
          this.$refs.form.resetFields();
        }
      },
    },
  };
</script>

<style scoped></style>
